import React from 'react';
import Header from './components/header/Header';
import Aboutt from './components/about/Aboutt';
import Contact from './components/contact/Contact';




const App  = () => {
    return (
    <>
    <Header />
    <Aboutt />
    <Contact />

    </>
    )
}

export default App